import React, { useState, useEffect} from 'react';
import css from './LandingPage.module.css';
import './carousel.css';
import { slidesToShowPlugin } from '@brainhubeu/react-carousel';
//import dynamic from 'next/dynamic';
import { post } from '../../util/api';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import image1 from '../../assets/new-verified-logo.jpeg';
import Slider from "react-slick";


// const Carousel = typeof window !== 'undefined' ? require('@brainhubeu/react-carousel').default : dynamic(() => import('@brainhubeu/react-carousel'), {
//     ssr: false,
//   });
  

function SectionGuides() {


    const [hosts, setHosts] = useState([])


    useEffect(() => {

        post('/api/show-hosts',{}).then(resp =>{
          setHosts(resp.slice(0, 8))
        }).catch(e => console.log(e))

    
        if(typeof window === "undefined"){
         console.log("ceva")
        }else{
          //require('@brainhubeu/react-carousel/lib/style.css');
          
          require("slick-carousel/slick/slick.css");
          require("slick-carousel/slick/slick-theme.css");
        }


      
    }, []);


    const isServer = typeof window === 'undefined';

    const slidesToShow = typeof window !== 'undefined' ? window.innerWidth < 700 ? 3 : 5 : 5;

    const settings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: slidesToShow,
      slidesToScroll: 1
    };

    return (
        <div>
          <h2 className={css.title}>
            <FormattedMessage id="SectionGuides.title"/>
          </h2>
             {/* <Carousel 
      plugins={[
      
        'arrows',
        {
          resolve: slidesToShowPlugin,
          options: {
           numberOfSlides: 4
          }
        },
      ]}

      breakpoints={{
        780: {
          plugins: [
           {
             resolve: slidesToShowPlugin,
             options: {
              numberOfSlides: 1
             }
           },
         ]
        },
      }}
      draggable={isServer ? true : window.innerWidth < 800 ? true : false}

      > */}

      <Slider {...settings}>

        {hosts.map(h => {
          return(
                <div className={css.guideSlide}>
                    <a href={`/u/${h.id.uuid}`}>
                      <img className={css.guidePic} src={h.profileImage.attributes?.variants.default.url} />
                      <p className={css.guideText}>
                        <table>
                          <tr>
                            <td>{h.attributes.profile.displayName}</td>
                            <td>
                              {h.attributes.profile.publicData.isVerified ? 
                                (<img src={image1} alt="verified" width={65} height={20} />):null}
                            </td>
                          </tr>
                        </table>
                      </p>
                    </a>
                </div>
          )
        })}

        </Slider>
          
          {/* </Carousel> */}
        </div>
    )
}

export default SectionGuides
