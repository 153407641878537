import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { injectIntl, intlShape } from '../../util/reactIntl';
import { isScrollingDisabled } from '../../ducks/UI.duck';
import config from '../../config';
import {
  Page,
  SectionHero,
  SectionHowItWorks,
  SectionLocations,
  LayoutSingleColumn,
  LayoutWrapperTopbar,
  LayoutWrapperMain,
  LayoutWrapperFooter,
  Footer,
  Button
} from '../../components';
import { TopbarContainer } from '../../containers';
import SectionGuides from './SectionGuides';
import SectionListings from './SectionListings';
import facebookImage from '../../assets/saunatimeFacebook-1200x630.jpg';
import twitterImage from '../../assets/saunatimeTwitter-600x314.jpg';
import css from './LandingPage.module.css';
import './SectionCarousel.css';

export const LandingPageComponent = props => {
  const { history, intl, location, scrollingDisabled } = props;

  // Schema for search engines (helps them to understand what this page is about)
  // http://schema.org
  // We are using JSON-LD format
  const siteTitle = config.siteTitle;
  const schemaTitle = intl.formatMessage({ id: 'LandingPage.schemaTitle' }, { siteTitle });
  const schemaDescription = intl.formatMessage({ id: 'LandingPage.schemaDescription' });
  const schemaImage = `${config.canonicalRootURL}${facebookImage}`;

  return (
    <Page
      className={css.root}
      scrollingDisabled={scrollingDisabled}
      contentType="website"
      description={schemaDescription}
      title={schemaTitle}
      facebookImages={[{ url: facebookImage, width: 1200, height: 630 }]}
      twitterImages={[
        { url: `${config.canonicalRootURL}${twitterImage}`, width: 600, height: 314 },
      ]}
      schema={{
        '@context': 'http://schema.org',
        '@type': 'WebPage',
        description: schemaDescription,
        name: schemaTitle,
        image: [schemaImage],
      }}
    >
      <LayoutSingleColumn>
        <LayoutWrapperTopbar>
          <TopbarContainer />
        </LayoutWrapperTopbar>
        <LayoutWrapperMain>

          <div className={css.heroContainer}>
            <SectionHero className={css.hero} history={history} location={location} />
          </div> 
          <h2 className={css.title}>Browse listings by category</h2>
          <div className={css.buttonsWrapper}>
              <div className={css.buttonsSubsection}>
              <button className={css.homepageButton} onClick={() => history.push('/s?pub_subcategories=accomodation')}>
              🏠Accomodation
              </button>
             
              <button className={css.homepageButton} onClick={() => history.push('/s?pub_subcategories=jobs')}>
              👷Jobs
              </button>
              </div>

              <div className={css.buttonsSubsection}>
              <button className={css.homepageButton} onClick={() => history.push('/s?pub_subcategories=services')}>
              🚚Services
              </button>

              <button className={css.homepageButton} onClick={() => history.push('/s?pub_subcategories=supplies')}>
              📦Supplies
              </button>
              </div>
          </div>
          <ul className={css.sections}>
            {/* <li className={css.section}>
                <div className={css.sectionContentFirstChild}>
                  <SectionGuides />
                  <br/>
                  <Button onClick={() => history.push('/s')} className={css.seeAllButton}>See all hosts</Button>
                </div>
              </li> */}

            <li className={css.section}>
              <div className={css.sectionContent}>
                <SectionListings 
                searchParam={{
                  pub_category: 'offeringPlace',
                  pub_profileType: 'private'
                }}
                title={'Discover listings from users'}
                />
              </div>
            </li>

            <li className={css.section}>
              <div className={css.sectionContent}>
                <SectionListings 
                searchParam={{
                  pub_category: 'offeringPlace',
                  pub_organisationType: 'church',
                  pub_profileType: 'organisation'
                }}
                title={'Discover listings from Church Organizations'}
                />
              </div>

            </li>

            <br/>
            {/* <li className={css.section}>
              <div className={css.sectionContent}>
                <SectionHowItWorks />
              </div>
            </li> */}
          </ul>
        </LayoutWrapperMain>
        <LayoutWrapperFooter>
          <Footer />
        </LayoutWrapperFooter>
      </LayoutSingleColumn>
    </Page>
  );
};

const { bool, object } = PropTypes;

LandingPageComponent.propTypes = {
  scrollingDisabled: bool.isRequired,

  // from withRouter
  history: object.isRequired,
  location: object.isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

const mapStateToProps = state => {
  return {
    scrollingDisabled: isScrollingDisabled(state),
  };
};

// Note: it is important that the withRouter HOC is **outside** the
// connect HOC, otherwise React Router won't rerender any Route
// components since connect implements a shouldComponentUpdate
// lifecycle hook.
//
// See: https://github.com/ReactTraining/react-router/issues/4671
const LandingPage = compose(
  withRouter,
  connect(mapStateToProps),
  injectIntl
)(LandingPageComponent);

export default LandingPage;
